.footer{
     display: flex;
    flex-direction: column; 
    
}

.footer>hr{
    width: 100%;
}
.footer-img{
    display: flex;
    gap: 4rem;
    justify-content: center;
    margin-top: 6rem;
}

.footer-img>img{
    width: 2rem;
    cursor: pointer;

}

.footer-logo{
    display: flex;
    justify-content: center;
    margin: 4rem;
}

.footer-logo>img{
    width: 10rem;
}

.footer-blur-1{
    bottom: 1;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.footer-blur-2{
    bottom: 1;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}