.plans{
    display: flex;
    flex-direction: column;
    margin-top: -4rem;
    padding: 0 2rem;
    gap: 4rem;
    position: relative;
}

.plans-heading{
    display: flex;
    gap: 2rem;
    justify-content: center ;
}

.plans-heading>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    color: white;
    justify-content: center;
    text-transform: uppercase
}
.plans-heading>:nth-child(1){
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    justify-content: center;
    text-transform: uppercase
}
.plans-heading>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    justify-content: center;
    text-transform: uppercase
}

.plans-card{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.plan-data{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: white;
    background-color: var(--caloryCard);
    padding: 1.5rem;
    width: 15rem;
}

.plans-card>:nth-child(2){
    background-color:  #ffa739;
    transform: scale(1.1);
}

.plan-data svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan-name{
    font-size: 1rem;
    font-weight: bold;
}

.plan-price{
    font-size: 3rem;
    font-weight: bold;
}

.plans-seemore{
  font-size: 0.8rem;
}

.features{
 display: flex;
 flex-direction: column;
 gap: 1rem;
}

.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.plans-card>:nth-child(2)>svg{
    fill: white;
}
.plans-card>:nth-child(2)>button{
   color:  #ffa739;
}

.plans-blur-1{
    width: 32rem;
    height: 22rem;
    left: 0;
    top: 6rem;
}
.plans-blur-2{
    width: 32rem;
    height: 22rem;
    right: 0;
    top: 10rem;
}

@media screen and (max-width:768px) {
    .plans{
        flex-direction: column;
        margin: 4px;
    }
    .plans-heading{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .plans-heading>span:nth-child(1){
        font-size: x-large;
    }
    .plans-heading>span:nth-child(2){
        font-size: x-large;
    }
    .plans-heading>span:nth-child(3){
        font-size: x-large;
    }
    .plans-card{
        flex-direction: column;
    }
    .plans-card>:nth-child(2){
        transform: none;
    }
   
}

