
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header ul{
    display: flex;
    list-style: none;
    gap: 2rem;
    color: white;
}

.header ul li:hover{
    cursor: pointer;
    color: var(--orange);
}

.header-logo{
    padding: 2rem;
}

.header-logo-img{
    width: 10rem;
    height: 3rem;
}

@media screen and (max-width: 768px) {
    .header-menu{
        position: fixed;
        right: 2rem;
        z-index: 99;
        top: 1rem;
    }
    .header-menu-items{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
}

