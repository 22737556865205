.programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-heading{
    display: flex;
    gap: 5rem;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    color: white;
    justify-content: center;
    text-transform: uppercase;
}

.programs-categories{
    display: flex;
    gap: 1rem;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: var(--gray);
    gap: 1rem;
    padding: 2rem;
    color: white;
    justify-content: space-between;
}

.category>:nth-child(1){
   width: 2rem;
   height: 2rem; 
   fill: white;
}
.category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
}
.category>:nth-child(3){
   font-size: 0.9rem;
   line-height: 25px;
   font-weight: normal;
}

.category:hover{
    cursor: pointer;
    background-color:    #ffa739 
}

@media screen and (max-width: 768px) {
    .programs-heading{
        margin-top: 4rem;
        flex-direction: column;
        font-size: 2rem;
        gap: 1rem;
        align-items: center;
        justify-content: center;

    }
    .programs-categories{
        flex-direction: column;
    }
}