.join{
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
}

.join-left{
    display: flex;
    flex-direction: column;
}

.join-left-text{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.join-left-text>hr{
    border: 2px solid var(--orange);
    border-radius: 20%;
    width: 10.5rem;
    position: absolute;
    margin: -10px 0;
}

.join-right{
    display: flex;
    justify-content: center;
    align-items: center;
}

.email{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}

::placeholder{
    color: var(--lightgray);
}

.join-btn{
    background-color: var(--orange);
    color: white;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .join{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .join-left{
        flex-direction: column;
    }
    .join-left-text{
        flex-direction: column;
        font-size: x-large;
    }
    .join-right{
        padding: 2rem;
    }
}